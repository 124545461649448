// ==================================================
//  Mainvisual
// ==================================================
document.addEventListener("DOMContentLoaded", function () {
  // テキストアニメーション
  setTimeout(function () {
    const MainvisualTextEnElms = document.querySelector(".js-mainvisual-textEn");
    MainvisualTextEnElms.classList.add("js-mainvisual-textEn-active");
    const MainvisualTextJaElms = document.querySelector(".js-mainvisual-textJa");
    MainvisualTextJaElms.classList.add("js-mainvisual-textJa-active");
  }, 600);

  // スライダー
  var splideMainvisual = new Splide(".js-splide-mainvisual", {
    type: "loop",
    arrows: false,
    pagination: false,
    autoWidth: true,
    autoHeight: true,
    drag: false, // or "free"
    // flickPower: 300,
    gap: "30px",
    autoplay: true,
    interval: 4000,
    speed: 800,
    focus: "center",
    pauseOnHover: false,
    pauseOnFocus: false,
    updateOnMove: true,
    start: 5,
    breakpoints: {
      600: {
        gap: "13px",
      },
    },
  });
  splideMainvisual.mount();

  // let splideMainvisualSlideElms = document.querySelectorAll(".js-splide-mainvisual .splide__slide");
  // let splideCount = 1;
  // splideMainvisual.on("move", (newIndex) => {
  //   splideMainvisual.Components.Slides.getAt(newIndex).slide.style.opacity = 0.15;
  //   splideMainvisual.Components.Slides.getAt(newIndex + 1).slide.style.opacity = 1;
  // });
});

// ==================================================
//  Visual Slider
// ==================================================
document.addEventListener("DOMContentLoaded", function () {
  // スライダー
  var visualSliderElms = document.getElementsByClassName("js-splide-visualSlider");
  for (var i = 0; i < visualSliderElms.length; i++) {
    new Splide(visualSliderElms[i], {
      type: "loop",
      arrows: false,
      pagination: false,
      autoScroll: {
        speed: 0.5,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
      autoWidth: true,
      autoHeight: true,
      drag: false, // or "free"
      // flickPower: 300,
      breakpoints: {
        600: {
          autoScroll: {
            speed: 0.3,
          },
        },
      },
    }).mount(window.splide.Extensions);
  }
});

// ==================================================
//  Header
// ==================================================
window.addEventListener("scroll", function () {
  // 追従
  const headerFixed = document.querySelector(".js-fixedScroll");
  const mainvisual = document.querySelector(".mainvisual");
  const mainvisualHeight = mainvisual.clientHeight;
  const headerFixedActiveClass = "js-fixedScroll-active";
  const scrollTop = window.scrollY;
  if (!is_mobile()) {
    if (mainvisualHeight < scrollTop) {
      headerFixed.classList.add(headerFixedActiveClass);
    } else {
      headerFixed.classList.remove(headerFixedActiveClass);
    }
  }
});
